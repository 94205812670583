var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Y0,Z0,c1,Ppa,Opa,Qpa;$CLJS.V0=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Le(function(c){return $CLJS.Dd(b,c)},a)};$CLJS.W0=function(a,b){return $CLJS.Jz($CLJS.Oe(a),b)};$CLJS.X0=function(a){var b;a:for(b=a;;)if($CLJS.td(b))b=$CLJS.Li.h(b);else{b=$CLJS.Bd(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.fD("Not something with an ID: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))])));};
Y0=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=Y0[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Y0._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("MetadataProvider.database",a);}return a};Z0=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=Z0[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Z0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.field",a);}return a};$CLJS.$0=function(a){return Y0($CLJS.d_(a))};
$CLJS.a1=function(a,b){return Z0($CLJS.d_(a),b)};$CLJS.b1=function(a){var b=$CLJS.EV(a,0),c=$CLJS.O(b),d=$CLJS.K.g(c,$CLJS.eO),e=$CLJS.K.g(c,$CLJS.NU);return $CLJS.Ad(function(){var f=$CLJS.$0(a);$CLJS.n(f)?(f=$CLJS.O(f),f=$CLJS.K.g(f,$CLJS.Li),f=$CLJS.E.g($CLJS.UO.h(a),f)):f=null;if($CLJS.n(f)){f=$CLJS.n(d)?$CLJS.e_(a,d):d;if($CLJS.n(f))return f;f=$CLJS.n(e)?$CLJS.g_(a,e):e;return $CLJS.n(f)?f:$CLJS.E.g($CLJS.jj.h(c),$CLJS.aV)&&$CLJS.E.g($CLJS.bZ,$CLJS.cZ.h($CLJS.$0(a)))}return f}())};
c1=function(a,b){return function f(d,e){try{if($CLJS.vd(e)&&3===$CLJS.D(e))try{var k=$CLJS.F(e,0);if($CLJS.ce(k,$CLJS.iF))try{var l=$CLJS.F(e,1);if($CLJS.n($CLJS.dD($CLJS.td,$CLJS.Oe($CLJS.dD($CLJS.tA,$CLJS.pi)))(l)))try{var m=$CLJS.F(e,2);if($CLJS.n($CLJS.dD($CLJS.Bd,$CLJS.vl)(m))){var t=$CLJS.F(e,2),u=$CLJS.F(e,1);return $CLJS.n($CLJS.Me(new $CLJS.Rg(null,new $CLJS.h(null,1,[Opa,null],null),null),d))?e:$CLJS.pk.v(e,1,$CLJS.Ek,null!=$CLJS.tA.h(u)&&$CLJS.lk.g($CLJS.tA.h(u),$CLJS.tA.h($CLJS.a1(b,t)))?
null:$CLJS.Ek.l($CLJS.H([$CLJS.Dd(u,$CLJS.tA)?null:new $CLJS.h(null,1,[$CLJS.KX,!0],null),$CLJS.Gl($CLJS.a1(b,t),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.pi],null))])))}throw $CLJS.Z;}catch(x){if(x instanceof Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof
Error){v=x;if(v===$CLJS.Z)return $CLJS.XV(f,d,e);throw v;}throw x;}}($CLJS.xf,a)};Ppa=new $CLJS.M(null,"is-native","is-native",-1797717934);Opa=new $CLJS.M("mbql","stage-metadata","mbql/stage-metadata",1090845629);Qpa=new $CLJS.M(null,"is-editable","is-editable",269018020);$CLJS.S0.m(null,$CLJS.gV,function(a){return $CLJS.R0(a,new $CLJS.h(null,2,[$CLJS.Wi,$CLJS.mh,$CLJS.qK,$CLJS.Qe($CLJS.ok,$CLJS.S0)],null))});$CLJS.T_.m(null,$CLJS.gV,function(){throw $CLJS.Uh("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.W_.m(null,$CLJS.gV,function(a,b,c,d){return $CLJS.X_.v(a,b,$CLJS.EV(c,b),d)});$CLJS.N_.m(null,$CLJS.gV,function(a,b,c,d){return $CLJS.m_.v(a,b,$CLJS.EV(c,b),d)});
$CLJS.V_.m(null,$CLJS.gV,function(a,b,c){a=$CLJS.EV(c,0);a=$CLJS.E.g($CLJS.jj.h(a),$CLJS.aV);return new $CLJS.h(null,2,[Ppa,a,Qpa,$CLJS.b1(c)],null)});$CLJS.d1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j($CLJS.N,$CLJS.Ti,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.jj.h($CLJS.EV(f,0))},e,a,b,c,d)}();$CLJS.d1.m(null,$CLJS.Oh,function(){return!0});
$CLJS.e1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.jj,$CLJS.gV,$CLJS.gD,$CLJS.d_(e),$CLJS.UO,d,$CLJS.qK,f],null)}function b(d,e){var f=$CLJS.Li.h($CLJS.$0(d));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.f1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.gB],null),$CLJS.Ti,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.XA(k)},e,a,b,c,d)}();$CLJS.f1.m(null,$CLJS.pA,function(a,b){a:{var c=$CLJS.MD;$CLJS.MD=!0;try{var d=$CLJS.XX.h(b);break a}finally{$CLJS.MD=c}d=void 0}b=c1(d,a);return $CLJS.e1.g(a,$CLJS.qK.h(b))});
$CLJS.f1.m(null,$CLJS.gV,function(a,b){var c=$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.TX);var d=$CLJS.d_(a),e=$CLJS.fk.g($CLJS.R.j(c,$CLJS.gD,d),$CLJS.TX);a=$CLJS.qK.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.qK,$CLJS.ok.g(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.vd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.ce(v,$CLJS.qA))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.dD($CLJS.td,$CLJS.Oe($CLJS.dD($CLJS.tA,$CLJS.pi)))(x))){var A=$CLJS.F(t,1),C=$CLJS.F(t,2),
G=function(){try{return $CLJS.W0(function(S){return $CLJS.E.g($CLJS.gj,S)},$CLJS.Gl($CLJS.dd($CLJS.Gpa.j(e,k,C)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.pi],null)))}catch(S){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.Ek.l($CLJS.H([G,A])),C],null)}throw $CLJS.Z;}catch(S){if(S instanceof Error){var J=S;if(J===$CLJS.Z)throw $CLJS.Z;throw J;}throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error){J=S;if(J===$CLJS.Z)throw $CLJS.Z;throw J;}throw S;}else throw $CLJS.Z;
}catch(S){if(S instanceof Error){J=S;if(J===$CLJS.Z)return $CLJS.XV(u,m,t);throw J;}throw S;}}($CLJS.xf,c1(f,d))},$CLJS.Fl($CLJS.yr,a))):e});$CLJS.f1.m(null,$CLJS.SJ,function(a,b){return $CLJS.e1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.jj,$CLJS.UU,$CLJS.eO,$CLJS.X0(b)],null)],null))});$CLJS.f1.m(null,$CLJS.hK,function(a,b){return $CLJS.e1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.jj,$CLJS.UU,$CLJS.NU,$CLJS.X0(b)],null)],null))});