var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var rsa=function(a,b){return new $CLJS.ie(null,function(){a:for(var c=a,d=b;;){d=$CLJS.y(d);var e;if(e=d)e=$CLJS.z(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Hc(d);else break a}return d},null,null)},ssa=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rt(a,b),rsa(a,b)],null)},X4=function(a){var b=$CLJS.Ek.l;var c=$CLJS.O(a);var d=$CLJS.K.g(c,$CLJS.Q4);c=$CLJS.K.g(c,$CLJS.R4);if($CLJS.n($CLJS.n(d)?$CLJS.E.g(c,"currency"):d)){c=$CLJS.L4.h;d=$CLJS.mh.h(d);var e=$CLJS.q($CLJS.I4);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.L4,d);c=new $CLJS.h(null,2,[$CLJS.P4,c,$CLJS.S4,c],null)}else c=new $CLJS.h(null,1,[$CLJS.S4,2],null);b=b.call($CLJS.Ek,$CLJS.H([c,a]));return $CLJS.n($CLJS.T4.h(a))?$CLJS.R.l($CLJS.fk.g(b,$CLJS.T4),$CLJS.S4,$CLJS.T4.h(a),$CLJS.H([$CLJS.P4,$CLJS.T4.h(a)])):b},Y4=function(a,b){if(null!=a&&null!=a.Oe)a=a.Oe(a,b);else{var c=Y4[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=Y4._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("NumberFormatter.format-number-basic",
a);}return a},tsa=function(a,b){var c=$CLJS.Ly(a.formatToParts(b),$CLJS.H([new $CLJS.h(null,1,[$CLJS.ki,!0],null)]));a=$CLJS.n($CLJS.Me(function(d){return $CLJS.E.g($CLJS.Wi.h(d),"exponentMinusSign")},c))?c:function(){var d=ssa(function(f){return $CLJS.lk.g($CLJS.Wi.h(f),"exponentInteger")},c),e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.bf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Wi,"exponentPlusSign",$CLJS.ij,"+"],null)],null),$CLJS.H([d]))}();return $CLJS.Ne.g($CLJS.p,
$CLJS.cf.g(function(d){var e=$CLJS.O(d);d=$CLJS.K.g(e,$CLJS.Wi);e=$CLJS.K.g(e,$CLJS.ij);switch(d){case "exponentSeparator":return"e";default:return e}},a))},usa=function(a,b){switch($CLJS.R4.h(b)){case "scientific":return function(c){return tsa(a,c)};default:return function(c){return a.format(c)}}},Z4=function(a,b,c,d,e){this.options=a;this.Bg=b;this.Eg=c;this.de=d;this.li=e;this.C=393216;this.I=0},vsa=function(a,b){return $CLJS.Ne.g($CLJS.p,function(){return function e(d){return new $CLJS.ie(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.wd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.id(k,v),A=$CLJS.O(x);x=$CLJS.K.g(A,$CLJS.Wi);A=$CLJS.K.g(A,$CLJS.ij);var C=new $CLJS.Rg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);C=C.h?C.h(x):C.call(null,x);$CLJS.n(C)&&(x=$CLJS.E.g(x,"integer")?b:A,m.add(x));v+=1}else return!0}()?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}var t=$CLJS.z(f);t=$CLJS.O(t);
var u=$CLJS.K.g(t,$CLJS.Wi);t=$CLJS.K.g(t,$CLJS.ij);if($CLJS.n(function(){var v=new $CLJS.Rg(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.ae($CLJS.E.g(u,"integer")?b:t,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null},null,null)}($CLJS.Ly(a.Bg.formatToParts(1),$CLJS.H([$CLJS.ki,!0])))}())},$4=function(a){var b=$CLJS.E.g($CLJS.R4.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.Hk($CLJS.Jz($CLJS.Sa,$CLJS.zg([$CLJS.osa,
$CLJS.gsa,$CLJS.jsa,$CLJS.lsa,$CLJS.qsa,$CLJS.Q4,$CLJS.ksa,$CLJS.msa,$CLJS.hsa,$CLJS.isa],[$CLJS.S4.g(a,b),$CLJS.V4.h(a),$CLJS.U4.h(a),$CLJS.nsa.h(a),$CLJS.E.g($CLJS.R4.h(a),"scientific")?null:$CLJS.R4.g(a,"decimal"),$CLJS.Q4.h(a),!0,$CLJS.P4.g(a,b),$CLJS.E.g($CLJS.R4.h(a),"scientific")?"scientific":null,$CLJS.W4.h(a)]))));var c=$CLJS.V4.h(a);var d=$CLJS.Q4.h(a);c=$CLJS.n(d)?null==c||$CLJS.E.g(c,"symbol"):d;d=usa(b,a);return new Z4(a,b,c,d,$CLJS.N)},a5=function(a,b){return $4(X4(b)).Oe(null,a)},c5=
function(a,b){var c=$CLJS.fk.l(b,$CLJS.AA,$CLJS.H([$CLJS.R4])),d=$CLJS.yz(a);if(0===a)return"0";if(1E3>d)return $CLJS.b5.g?$CLJS.b5.g(a,c):$CLJS.b5.call(null,a,c);b=$CLJS.z($CLJS.ff(function(f){return d>=$CLJS.z(f)},wsa));var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.Ek.l($CLJS.H([c,new $CLJS.h(null,2,[$CLJS.P4,1,$CLJS.S4,1],null)]));return $CLJS.b5.g?$CLJS.b5.g(f,k):$CLJS.b5.call(null,f,k)}()),$CLJS.p.h(b)].join("")},xsa=function(a,b){var c=X4(b);if($CLJS.n(d5.h(c)))b=
d5.h(c);else if($CLJS.Ra($CLJS.T4.h(c))&&$CLJS.Ra($CLJS.P4.h(c))&&$CLJS.lk.g($CLJS.R4.h(c),"currency")&&$CLJS.yz(a)<($CLJS.E.g($CLJS.R4.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.fk.g(c,$CLJS.S4);c=$CLJS.W4.g(c,0);b=$4(b.call($CLJS.R,d,$CLJS.U4,2>c?2:c))}else b=$4(c);return Y4(b,a)};Z4.prototype.P=function(a,b){return new Z4(this.options,this.Bg,this.Eg,this.de,b)};Z4.prototype.O=function(){return this.li};
Z4.prototype.Oe=function(a,b){a=this.de.h?this.de.h(b):this.de.call(null,b);var c=$CLJS.psa.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.lk.g(c,".,"):c)&&(b=$CLJS.z(c),c=$CLJS.dd(c),a=$CLJS.Gz(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.Eg)&&(b=$CLJS.Q4.h(this.options),c=$CLJS.J4(b),a=$CLJS.Gz($CLJS.Gz(a,[$CLJS.Xg(b)," "].join(""),c),$CLJS.Xg(b),c));return a};
var d5=new $CLJS.M(null,"number-formatter","number-formatter",929788393),e5=new $CLJS.M(null,"scale","scale",-230427353),f5=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),ysa=new $CLJS.M(null,"scientific","scientific",316285837);var g5=new $CLJS.h(null,1,[$CLJS.V4,"symbol"],null),zsa=$CLJS.Hk(g5),wsa=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),h5=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j($CLJS.N,$CLJS.Ti,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.K.g(f,$CLJS.R4)},e,a,b,c,d)}();h5.m(null,$CLJS.Oh,function(a,b){return c5(a,b)});h5.m(null,"percent",function(a,b){return[$CLJS.p.h(c5(100*a,b)),"%"].join("")});h5.m(null,"currency",function(a,b){b=$CLJS.Ek.l($CLJS.H([b,g5]));var c=$4(b);return 1E3>$CLJS.yz(a)?c.Oe(null,a):vsa(c,c5(a,b))});h5.m(null,"scientific",function(a,b){return a5(a,$CLJS.Ek.l($CLJS.H([b,new $CLJS.h(null,2,[$CLJS.S4,1,$CLJS.P4,1],null)])))});
$CLJS.b5=function b5(a,b){b=$CLJS.fA(b);var d=$CLJS.O(b);b=$CLJS.K.g(d,$CLJS.AA);var e=$CLJS.K.g(d,f5),f=$CLJS.K.g(d,$CLJS.R4),k=$CLJS.K.g(d,e5);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.fk.g(d,e5),b5.g?b5.g(b,f):b5.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,f5,!1);return b5.g?b5.g(l,m):b5.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?h5.g(a,X4($CLJS.fk.g(d,$CLJS.AA))):$CLJS.E.g($CLJS.mh.h(f),ysa)?a5(a,d):xsa(a,d)};
module.exports={compact_currency_options_js:zsa,format_number:$CLJS.b5};