var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var F1,Upa,O1,P1,R1,Vpa,Wpa,Xpa,Ypa,Zpa,aqa,bqa,cqa,$pa,U1,V1;F1=function(a,b){var c=$CLJS.Cd,d=E1;for(a=$CLJS.y(a);;)if(null!=a){d=$CLJS.K.j(d,$CLJS.z(a),c);if(c===d)return b;a=$CLJS.B(a)}else return d};
$CLJS.G1=function(a){return function(b){var c=$CLJS.Ve(-1);return function(){function d(l,m){var t=c.nd(null,c.Lb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
$CLJS.H1=function(a,b,c){return null==c||$CLJS.Dd(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.I1=function(a,b){return $CLJS.Wf.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,new $CLJS.h(null,1,[$CLJS.wD,$CLJS.p.h($CLJS.GD())],null)],null),$CLJS.cf.h($CLJS.qV),b)};$CLJS.J1=function(a,b){return $CLJS.wV(a)&&$CLJS.E.g($CLJS.z(a),b)};$CLJS.K1=function(a,b){a=$CLJS.DV(a,b);return 0<a?a-1:null};
$CLJS.L1=function(a,b){if($CLJS.y(b)){if($CLJS.E.g($CLJS.D(b),1))return $CLJS.z(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.E.g($CLJS.D(b),2)?[$CLJS.p.h($CLJS.z(b)),a,$CLJS.p.h($CLJS.dd(b))].join(""):[$CLJS.is(", ",$CLJS.fs(b)),",",a,$CLJS.p.h($CLJS.fd(b))].join("")}return null};$CLJS.M1=function(a){return $CLJS.NU.h($CLJS.z($CLJS.qK.h(a)))};
$CLJS.N1=function(a,b,c,d){a=$CLJS.CV(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.EV(a,b);e=!($CLJS.y($CLJS.YE.h(e))||$CLJS.y($CLJS.XE.h(e)));c=$CLJS.PV.l(a,b,$CLJS.pk,$CLJS.H([c,function(f){return $CLJS.Yd.g($CLJS.yf(f),$CLJS.qV.h(d))}]));return e?$CLJS.pk.M((0,$CLJS.PV)(c,b,function(f){return $CLJS.SV($CLJS.fk.l(f,$CLJS.NQ,$CLJS.H([$CLJS.QD])),$CLJS.DM,function(k){return $CLJS.ok.g(function(l){return $CLJS.fk.g(l,$CLJS.QD)},k)})}),$CLJS.qK,$CLJS.mk.g(function(f){return $CLJS.Wf.g($CLJS.xf,f)},$CLJS.tk),0,
$CLJS.DV(a,b)+1):c};Upa=function(a,b){b=$CLJS.g_(a,b);return $CLJS.n(b)?$CLJS.q1(a,b):null};O1=function(a){return $CLJS.Wf.j($CLJS.Sg,$CLJS.nk.h(function(b){return $CLJS.he(b)&&$CLJS.lk.g(b,$CLJS.jj)}),$CLJS.Ng(a))};P1=function(a){return $CLJS.Wf.j($CLJS.N,$CLJS.Il(function(b,c){$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);c=$CLJS.O(c);c=$CLJS.K.g(c,$CLJS.wD);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.YE.h(a))};
$CLJS.Q1=function(a,b,c){var d=$CLJS.Ek.l;b=$CLJS.Ra($CLJS.K1(a,b))?function(){var f=$CLJS.M1(a);return $CLJS.n(f)?(f=Upa(a,f),$CLJS.n(f)?$CLJS.rG(function(k){return $CLJS.E.g($CLJS.Li.h(k),c)},f):null):null}():null;try{var e=$CLJS.a1(a,c)}catch(f){e=null}return d.call($CLJS.Ek,$CLJS.H([b,e]))};
R1=function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null),d=$CLJS.O(c);c=$CLJS.K.g(d,$CLJS.tO);d=$CLJS.K.g(d,$CLJS.EM);$CLJS.I(a,2,null);a=$CLJS.n(d)?$CLJS.E.g(d,$CLJS.YJ.h(b)):d;return $CLJS.n(a)?a:$CLJS.E.g($CLJS.zz($CLJS.fZ,$CLJS.$Y)(b),c)};
Vpa=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null),d=$CLJS.Be($CLJS.ff(function(e){var f=$CLJS.E.g($CLJS.LY.h(e),c);return f?R1(a,e):f},b));return $CLJS.n(d)?d:$CLJS.ff(function(e){var f=$CLJS.E.g($CLJS.T.h(e),c);return f?R1(a,e):f},b)};
Wpa=function(a,b,c){$CLJS.I(a,0,null);var d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.Be($CLJS.ff(function(k){var l=$CLJS.E.g($CLJS.Li.h(k),e);if(l){if(l=$CLJS.Ra($CLJS.tO.h(d))){l=$CLJS.MJ.h(k);var m=new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.PY,null,$CLJS.UY,null],null),null);l=m.h?m.h(l):m.call(null,l)}return $CLJS.n(l)?l:R1(a,k)}return l},b));if($CLJS.n(f))return f;b=$CLJS.n(c)?$CLJS.Be($CLJS.ff(function(k){return $CLJS.E.g($CLJS.Li.h(k),e)},b)):null;return $CLJS.n(b)?b:$CLJS.xf};
Xpa=function(a){var b=$CLJS.S1.g($CLJS.MJ.h(a),$CLJS.FY);return $CLJS.n(b)?b:$CLJS.oD.h(a)};
Ypa=function(a,b){b=$CLJS.Be($CLJS.nk.g($CLJS.YJ,b));if($CLJS.n(b))if($CLJS.Ra($CLJS.B(b)))a=$CLJS.z(b);else{if($CLJS.n($CLJS.S1.g($CLJS.z(a),$CLJS.iF))){var c=$CLJS.Be($CLJS.nk.g(Xpa,b));c=$CLJS.n(c)?$CLJS.B(c)?null:$CLJS.z(c):null}else c=null;a=$CLJS.n(c)?c:console.warn($CLJS.Uh("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.V,a,$CLJS.BV,b],null)))}else a=null;return a};
Zpa=function(a,b){b=$CLJS.Be($CLJS.nk.g(function(c){var d=$CLJS.zz($CLJS.fZ,$CLJS.$Y)(c);$CLJS.n(d)?(c=$CLJS.MJ.h(c),d=new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.PY,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Ra(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Ra($CLJS.B(b))?$CLJS.z(b):Ypa(a,b):null};
aqa=function(a,b){var c=$CLJS.DD(a);c=$CLJS.O(c);var d=$CLJS.K.g(c,$CLJS.tO);return $CLJS.n(d)?(b=$CLJS.Be($CLJS.ff(function(e){return $CLJS.E.g($CLJS.zz($CLJS.fZ,$CLJS.$Y)(e),d)},b)),$CLJS.n(b)?$CLJS.Ra($CLJS.B(b))?$CLJS.z(b):console.warn("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.V,a,$pa,b],null)):null):Zpa(a,b)};bqa=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
cqa=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.T1=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.dqa=new $CLJS.M(null,"matching-refs","matching-refs",324693611);$pa=new $CLJS.M(null,"matches","matches",635497998);U1=new $CLJS.M(null,"left","left",-399115937);V1=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);$CLJS.S1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Ti,$CLJS.gB],null),$CLJS.Ti,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.XA(f);k=$CLJS.XA(k);return $CLJS.lk.g(f,k)?V1:f},e,a,b,c,d)}();$CLJS.S1.m(null,V1,function(){return!1});
$CLJS.S1.m(null,$CLJS.pA,function(a,b){var c=O1(a),d=O1(b);return $CLJS.E.g(c,d)&&$CLJS.Le(function(e){return $CLJS.S1.g($CLJS.K.g(a,e),$CLJS.K.g(b,e))},c)});$CLJS.S1.m(null,$CLJS.nA,function(a,b){var c=$CLJS.E.g($CLJS.D(a),$CLJS.D(b));if(c)for(c=$CLJS.y(a),$CLJS.z(c),$CLJS.B(c),c=$CLJS.y(b),$CLJS.z(c),$CLJS.B(c),c=a,a=b;;){c=$CLJS.y(c);b=$CLJS.z(c);var d=$CLJS.B(c);c=b;b=d;a=$CLJS.y(a);d=$CLJS.z(a);a=$CLJS.B(a);c=$CLJS.S1.g(c,d);if($CLJS.n(c)){if(c=$CLJS.pd(b))return c;c=b}else return c}else return c});
var E1=null;$CLJS.S1.m(null,$CLJS.UU,function(a,b){var c=E1;E1=new $CLJS.h(null,2,[U1,P1(a),$CLJS.uw,P1(b)],null);try{var d=$CLJS.kH($CLJS.S1,$CLJS.pA);return d.g?d.g(a,b):d.call(null,a,b)}finally{E1=c}});
$CLJS.S1.m(null,$CLJS.YE,function(a,b){var c=$CLJS.I(a,0,null),d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null),l=$CLJS.I(b,2,null);return(a=$CLJS.E.l(3,$CLJS.D(a),$CLJS.H([$CLJS.D(b)])))?(c=$CLJS.E.g(c,f))?(d=$CLJS.S1.g(d,k),$CLJS.n(d)?$CLJS.n(E1)?$CLJS.E.g(F1(new $CLJS.P(null,2,5,$CLJS.Q,[U1,e],null),cqa),F1(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uw,l],null),bqa)):$CLJS.E.g(e,l):d):c:a});
$CLJS.S1.m(null,$CLJS.Oh,function(a,b){if($CLJS.td(a)){var c=$CLJS.kH($CLJS.S1,$CLJS.pA);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.sd(a)?(c=$CLJS.kH($CLJS.S1,$CLJS.nA),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.E.g(a,b)});
$CLJS.W1=function(){function a(f,k,l,m,t){var u=$CLJS.wV(l)?l:$CLJS.pV(l),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null),A=$CLJS.I(u,2,null),C=e.j?e.j(u,m,t):e.call(null,u,m,t);if($CLJS.n(C))return C;C=$CLJS.n(function(){var J=$CLJS.S1.g(v,$CLJS.YE);return $CLJS.n(J)?$CLJS.zK.h(x):J}())?$CLJS.rG(function(J){var S=$CLJS.S1.g($CLJS.MJ.h(J),$CLJS.jK);return $CLJS.n(S)?$CLJS.S1.g($CLJS.T.h(J),$CLJS.zK.h(x)):S},m):null;if($CLJS.n(C))return C;if($CLJS.n($CLJS.n(f)?"number"===typeof A:f)&&(m=$CLJS.Be($CLJS.nk.g($CLJS.Li,
m)),$CLJS.n(m))){var G=$CLJS.wV(l)?$CLJS.Q1(f,k,A):l;if($CLJS.n(G))return f=$CLJS.FD($CLJS.R.j(u,2,function(){var J=$CLJS.LY.h(G);return $CLJS.n(J)?J:$CLJS.T.h(G)}()),$CLJS.Qe($CLJS.Ek,new $CLJS.h(null,1,[$CLJS.tA,$CLJS.gj],null))),e.j?e.j(f,m,t):e.call(null,f,m,t)}return null}function b(f,k,l,m){return e.M?e.M(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=$CLJS.I(f,0,null);$CLJS.I(f,1,null);var t=$CLJS.I(f,2,null);l=$CLJS.O(l);l=$CLJS.K.g(l,$CLJS.T1);switch(m instanceof $CLJS.M?
m.S:null){case "aggregation":return $CLJS.rG(function(u){return $CLJS.E.g($CLJS.MJ.h(u),$CLJS.jK)&&$CLJS.E.g($CLJS.C0.h(u),t)},k);case "expression":case "field":switch(k="string"===typeof t?Vpa(f,k):Wpa(f,k,l),$CLJS.D(k)){case 0:return null;case 1:return $CLJS.z(k);default:return aqa(f,k)}default:throw $CLJS.Uh("Unknown type of ref",new $CLJS.h(null,1,[$CLJS.V,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,
f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.M=a;return e}();
$CLJS.eqa=function(){function a(d,e,f,k){return $CLJS.W1.v(d,e,$CLJS.sY.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();