var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.j1=function(a,b){var c=$CLJS.FV(b);return $CLJS.n(c)?$CLJS.g_(a,c):$CLJS.e_(a,b)};$CLJS.k1=function(a){var b=$CLJS.XA(a);b=b instanceof $CLJS.M?b.S:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.tO.h($CLJS.DD(a));case "metadata/column":return $CLJS.fZ.h(a);case "mbql/join":return $CLJS.UD.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.l1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.m1=function(a,b){var c=$CLJS.k1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.K.g(d,$CLJS.YJ);d=$CLJS.K.g(d,$CLJS.ZJ);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.j1(a,d),$CLJS.n(d))){d=$CLJS.T.h(d);a=$CLJS.T.h($CLJS.a1(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.l1(a,$CLJS.T.h(b)):$CLJS.T.h(b)};
$CLJS.n1=function(a,b,c,d){var e=$CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.ZJ),c);return $CLJS.Wf.j($CLJS.xf,$CLJS.mk.l($CLJS.ef($CLJS.MY),$CLJS.ef($CLJS.Li),$CLJS.ef($CLJS.mk.g($CLJS.cl,$CLJS.Li)),$CLJS.H([$CLJS.cf.h(function(f){f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.Li),l=$CLJS.K.g(f,$CLJS.MY);return $CLJS.R.l($CLJS.a1(a,l),$CLJS.D_,k,$CLJS.H([$CLJS.E_,$CLJS.fZ.h(f)]))}),$CLJS.nk.h(function(f){return $CLJS.Dd(e,$CLJS.ZJ.h(f))}),$CLJS.eD(function(f){f=$CLJS.O(f);var k=$CLJS.K.g(f,$CLJS.ZJ),l=$CLJS.K.g(f,
$CLJS.D_),m=$CLJS.K.g(f,$CLJS.E_);f=$CLJS.e_(a,k);return function v(u){return new $CLJS.ie(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.wd(x)){var A=$CLJS.ic(x),C=$CLJS.D(A),G=$CLJS.le(C);return function(){for(var X=0;;)if(X<C){var W=$CLJS.id(A,X),da=$CLJS.R.l(W,$CLJS.YJ,l,$CLJS.H([$CLJS.A_,m,$CLJS.MJ,$CLJS.HY,$CLJS.vY,$CLJS.T.h(W)]));W=G;var ra=$CLJS.R,Ma=ra.j,sb=da;da=$CLJS.m1(a,da);da=d.h?d.h(da):d.call(null,da);ra=Ma.call(ra,sb,$CLJS.LY,da);W.add(ra);X+=1}else return!0}()?$CLJS.oe($CLJS.qe(G),
v($CLJS.jc(x))):$CLJS.oe($CLJS.qe(G),null)}var J=$CLJS.z(x),S=$CLJS.R.l(J,$CLJS.YJ,l,$CLJS.H([$CLJS.A_,m,$CLJS.MJ,$CLJS.HY,$CLJS.vY,$CLJS.T.h(J)]));return $CLJS.ae($CLJS.R.j(S,$CLJS.LY,function(){var X=$CLJS.m1(a,S);return d.h?d.h(X):d.call(null,X)}()),v($CLJS.Hc(x)))}return null}},null,null)}($CLJS.Y_.v(a,b,f,new $CLJS.h(null,2,[$CLJS.I_,d,$CLJS.C_,!1],null)))})])),c)};$CLJS.o1=function(a,b){return $CLJS.f1.g(a,b)};$CLJS.p1=function(a){return $CLJS.fD("Question {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))]))};
$CLJS.q1=function(a,b){var c=$CLJS.QD.h(b);$CLJS.n(c)||(c=$CLJS.SY.h(b),$CLJS.n(c)||(c=$CLJS.RY.h(b),c=null!=c?$CLJS.X_.h($CLJS.o1(a,$CLJS.XX.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Be($CLJS.td(c)?$CLJS.BV.h(c):$CLJS.sd(c)?c:null),$CLJS.n(c)?$CLJS.ok.g($CLJS.Xs(Spa,a,b),c):null):null};$CLJS.r1=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.s1=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.N_.m(null,$CLJS.hK,function(a,b,c){return $CLJS.zz($CLJS.kD,$CLJS.T)(c)});$CLJS.T_.m(null,$CLJS.hK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.K.g(a,$CLJS.T);c=$CLJS.K.g(a,$CLJS.kD);return $CLJS.Ra(c)?$CLJS.R.j(a,$CLJS.kD,$CLJS.i1.g($CLJS.xA,b)):a});
$CLJS.Y_.m(null,$CLJS.hK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.K.g(c,$CLJS.QD),f=$CLJS.K.g(c,$CLJS.SY);d=$CLJS.O(d);var k=$CLJS.K.g(d,$CLJS.C_),l=$CLJS.K.g(d,$CLJS.I_);return $CLJS.bf.g($CLJS.X_.v(a,b,c,d),$CLJS.n(k)?$CLJS.n1(a,b,$CLJS.bf.g(e,f),l):null)});$CLJS.Q_.m(null,$CLJS.NU,function(a,b){var c=$CLJS.EV(a,b);c=$CLJS.O(c);c=$CLJS.K.g(c,$CLJS.NU);if($CLJS.n(c)){var d=$CLJS.g_(a,c);a=$CLJS.n(d)?$CLJS.m_.v(a,b,d,$CLJS.n_):null;return $CLJS.n(a)?a:$CLJS.p1(c)}return null});
var Spa=function(){function a(d,e,f){var k=$CLJS.fk.g($CLJS.Ez(f,$CLJS.eA),$CLJS.s1);f=$CLJS.Ek.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tA,$CLJS.gj,$CLJS.jj,$CLJS.KJ],null),function(){var l=$CLJS.Li.h(k);if($CLJS.n(l))try{return $CLJS.a1(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.jj,$CLJS.KJ,$CLJS.MJ,$CLJS.PY,$CLJS.vY,$CLJS.zz($CLJS.vY,$CLJS.T)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.ZY,$CLJS.X0(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Ra(e)||$CLJS.Ra($CLJS.DY.h($CLJS.g_(d,
$CLJS.X0(e)))):!0)?$CLJS.R.j(f,$CLJS.r1,!0):f;return $CLJS.lk.g($CLJS.yj.h(k),$CLJS.Xh)?$CLJS.R.j(e,$CLJS.MY,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.W_.m(null,$CLJS.hK,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.K.g(b,$CLJS.I_);return $CLJS.ok.g(function(f){var k=$CLJS.qB($CLJS.LY,$CLJS.vY,$CLJS.T)(f);return $CLJS.R.j(f,$CLJS.LY,e.h?e.h(k):e.call(null,k))},$CLJS.q1(a,c))});