var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var cU,dU,eU,gU,hU,iU,jU,kU,lU,mU;cU=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);dU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);eU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.fU=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);gU=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);hU=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);iU=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
jU=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);kU=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);lU=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);mU=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.Y(cU,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.us,"Valid template tag :widget-type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.YT)));$CLJS.Y(gU,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dr,$CLJS.GL,$CLJS.vM,$CLJS.BE,$CLJS.Qs,$CLJS.Nj,$CLJS.BO],null));
$CLJS.Y(jU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.pD],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kD,$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Li,new $CLJS.h(null,1,[$CLJS.zr,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.pD,$CLJS.tr],null)],null)],null));
$CLJS.Y(kU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.el],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XM,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.ur],null)],null)],null));
$CLJS.Y(hU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.BE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.YD],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,cU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.zr,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fr,$CLJS.Gj],null)],null)],null)],null));
$CLJS.Y(iU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jU],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.GL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sL,$CLJS.pD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TO,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.fK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UO,new $CLJS.h(null,1,[$CLJS.zr,!0],null),$CLJS.WJ],null)],null)],null));
$CLJS.Y(mU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,$CLJS.vM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bQ,$CLJS.VJ],null)],null)],null));$CLJS.Y(lU,$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dr],null),$CLJS.MT));
$CLJS.Y(dU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lU],null)],null)],null)],null));
$CLJS.Y(eU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gU],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.zi,$CLJS.Wi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vM,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mU],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$n,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dU],null)],null)],null)],null));
$CLJS.Y($CLJS.fU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,$CLJS.pD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.us,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));